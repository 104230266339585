<template>
  <div :class="`dropdown-list-layout ${bgColor || ''}`">
    <div class="dropdown-list-layout__header">
      <h5>{{ listTitle }}</h5>

      <div
        :class="`dropdown-list-layout__header--arrow ${
          isDropdownOpen ? 'open' : ''
        }`"
        @click="isDropdownOpen = !isDropdownOpen"
      >
        <img :src="whiteArrow" alt="white-arrow" />
      </div>
    </div>

    <div
      :class="`dropdown-list-layout__content ${isDropdownOpen ? 'open' : ''}`"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IDropdownListLayoutProps } from "@/core/interfaces/components";

import whiteArrow from "@/assets/icons/white-arrow.svg";
import { ref } from "vue";

defineProps<IDropdownListLayoutProps>();

const isDropdownOpen = ref<boolean>(false);
</script>

<style scoped lang="scss">
@import "./DropDownListLayout.scss";
</style>
