import homeGray from "@/assets/icons/home-gray.svg";
import homeOrange from "@/assets/icons/home-orange.svg";
import analyticsGray from "@/assets/icons/analytics-gray.svg";
import analyticsOrange from "@/assets/icons/analytics-orange.svg";
import logoutGray from "@/assets/icons/logout-gray.svg";
import logoutOrange from "@/assets/icons/logout-orange.svg";
import clientsGray from "@/assets/icons/profile-gray.svg";
import clientsOrange from "@/assets/icons/profile-orange.svg";
import projectsGray from "@/assets/icons/projects-gray.svg";
import projectsOrange from "@/assets/icons/projects-orange.svg";
import settingsGray from "@/assets/icons/settings-gray.svg";
import settingsOrange from "@/assets/icons/settings-orange.svg";

import type { INavBarInterface } from "@/core/interfaces/components";
import { ROUTER_URLS } from "../enums/router";
import { NAVBAR_NAMES } from "../enums/components/navbar_names.enum";

export const NAV_BAR_CONFIG: INavBarInterface[] = [
  {
    path: ROUTER_URLS.HOME,
    title: NAVBAR_NAMES.DASHBOARD,
    activeIcon: homeOrange,
    passiveIcon: homeGray,
  },
  {
    path: ROUTER_URLS.CLIENTS,
    title: NAVBAR_NAMES.CLIENTS,
    activeIcon: clientsOrange,
    passiveIcon: clientsGray,
  },
  {
    path: ROUTER_URLS.PROJECTS,
    title: NAVBAR_NAMES.PROJECTS,
    activeIcon: projectsOrange,
    passiveIcon: projectsGray,
  },
  {
    path: ROUTER_URLS.ANALYTIC,
    title: NAVBAR_NAMES.ANALYTICS,
    activeIcon: analyticsOrange,
    passiveIcon: analyticsGray,
  },
  {
    path: ROUTER_URLS.SETTINGS,
    title: NAVBAR_NAMES.SETTINGS,
    activeIcon: settingsOrange,
    passiveIcon: settingsGray,
  },
  {
    path: ROUTER_URLS.SIGN_IN,
    title: NAVBAR_NAMES.LOG_OUT,
    activeIcon: logoutOrange,
    passiveIcon: logoutGray,
  },
];
