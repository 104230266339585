export enum CHECKBOXES_GROUP_KEYS {
  COMMUNICATE = "communicate",
  FROM_ABOUT = "from_about",
  DESIGN_TYPE = "design_type",
  CORPORATE_SITE = "corporate_site",
  E_COMMERCE = "e_commerce",
  MARKET_PLACE = "market_place",
  MOBILE_APP = "mobile_app",
  CRM = "crm",
  ERP = "erp",
  LANDING_PAGE = "landing_page",
  ONLINE_STORE = "online_store",
}

export enum CHECKBOXES_DROPDOWN_LIST_KEYS {
  REFINEMENT = "refinement",
  FROM_SCRATCH = "from_scratch",
}

export enum CLIENT_CHECKBOX_TYPE {
  COMMUNICATE = "COMMUNICATE",
  FROM_ABOUT = "FROM_ABOUT",
}

export enum SERVICES_CHECKBOX_TYPE {
  DESIGN = "DESIGN",
  FROM_SCRATCH = "FROM_SCRATCH",
  REFINEMENT = "REFINEMENT",
}
