// enums
import { ICON_TYPE } from "../enums/components";
// interfaces
import type { IIcon } from "../interfaces/components";
// constant
import { ICON_ALT } from "./icon_alt.constant";

import eyeIcon from "@/assets/icons/eye.svg";
import eyeOffIcon from "@/assets/icons/eye-off.svg";
import ArrowIcon from "@/assets/icons/arrow.svg";
import CloseIcon from "@/assets/icons/close.svg";
import TemplateIcon from "@/assets/icons/template.svg";
import BackIcon from "@/assets/icons/back-arrow.svg";

export const CRM_ICONS: Record<ICON_TYPE, IIcon> = {
  [ICON_TYPE.EYE]: { alt: ICON_ALT.HIDE_PASSWORD, src: eyeIcon },
  [ICON_TYPE.EYE_OFF]: { alt: ICON_ALT.HIDE_PASSWORD, src: eyeOffIcon },
  [ICON_TYPE.SELECT_ARROW]: { alt: ICON_ALT.SELECT_ARROW, src: ArrowIcon },
  [ICON_TYPE.CLOSE]: { alt: ICON_ALT.CLOSE, src: CloseIcon },
  [ICON_TYPE.TEMPLATE]: { alt: ICON_ALT.TEMPLATE, src: TemplateIcon },
  [ICON_TYPE.BACK]: { alt: ICON_ALT.BACK, src: BackIcon },
};
