export const recursiveKeyTransformation = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = key.replace(/[\s-]/g, "_").toLowerCase();
    if (value && typeof value === "object" && !Array.isArray(value)) {
      acc[newKey] = recursiveKeyTransformation(value);
    } else {
      acc[newKey] = value;
    }
    return acc;
  }, {} as Record<string, any>);
};
