export const API_AUTH = {
  LOGIN: "auth/login",
};

export const API_CLIENT = {
  CREATE: "client",
  GET_ALL: "client",
  UPDATE: (clientId: string) => `client/${clientId}`,
  GET_BY_ID: (clientId: string) => `get/${clientId}`,
};

export const API_COMPANY = {
  CREATE: "company",
};

export const API_SERVICES = {
  GET: "project/get-services",
};

export const API_PROJECT = {
  CREATE: "project",
};

export const API_PROJECT_SECTIONS = {
  GET: (servicesIds: number[]) =>
    `project/get-site-blocks?ids=[${servicesIds}]`,
};
