import type { UseModalResponse } from "@/core/interfaces/composables";
import { ref, type UnwrapRef } from "vue";

export const useModal = <
  T extends Record<string, any>
>(): UseModalResponse<T> => {
  const isOpen = ref<boolean>(false);
  const modalData = ref<T | null>(null);

  const open = (data?: UnwrapRef<T> | null) => {
    if (data) {
      modalData.value = data;
    }

    isOpen.value = true;
  };

  const close = () => {
    modalData.value = null;
    isOpen.value = false;
  };

  return {
    isOpen,
    open,
    close,
    modalData,
  };
};
