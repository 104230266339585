<template>
  <button
    :class="`filled-button ${bgColor} ${textColor} ${
      disabled ? 'disabled' : ''
    } ${borderColor || ''}`"
    :style="{ width: width || '', height: height || '' }"
    @click="($event) => emit('click', $event)"
  >
    <slot>
      <span>Basic Text</span>
    </slot>
  </button>
</template>

<script setup lang="ts">
import type { IButtonProps, IButtonEmits } from "@/core/interfaces/components";

defineProps<IButtonProps>();
const emit = defineEmits<IButtonEmits>();
</script>

<style scoped lang="scss">
@import "./FilledButton.scss";
</style>
