import { LOCAL_STORAGE_KEYS } from "@/core/enums/common/local_storage.enum";
import { ROUTER_URLS } from "@/core/enums/router/router_urls.enum";
import { isTokenExpired } from "@/helpers";
import { LocalStorageService } from "@/services/localStorageService";
import { useAuthStore } from "@/stores/authStore";
import { useSystemStore } from "@/stores/systemStore";
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import axios from "axios";

const axiosConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const instance: AxiosInstance = axios.create(axiosConfig);
const controller: AbortController | undefined = new AbortController();

instance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const systemStore = useSystemStore();

    if (!systemStore.hideLoader) {
      systemStore.setIsLoading(true);
    }

    if (isTokenExpired() && config.url !== ROUTER_URLS.SIGN_IN) {
      controller.abort();
    }

    const accessToken = LocalStorageService.read(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    const tokenType = LocalStorageService.read(LOCAL_STORAGE_KEYS.TOKEN_TYPE);

    if (accessToken) {
      config.headers.Authorization = `${tokenType} ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError<any>) => {
    const systemStore = useSystemStore();

    systemStore.setIsLoading(false);

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res: AxiosResponse) => {
    const systemStore = useSystemStore();

    systemStore.setIsLoading(false);

    return res;
  },

  (error: AxiosError<any>) => {
    const systemStore = useSystemStore();
    const { logout } = useAuthStore();

    systemStore.setIsLoading(false);
    const NON_AUTHORIZE_USER = error?.response?.status == 401;

    if (NON_AUTHORIZE_USER) {
      location.reload();
      logout();
    }

    return Promise.reject(error);
  }
);

export const API = instance;
