import { API } from "@/api/api";
import { API_AUTH } from "@/core/constants/api/api-urls";
import type { ILoginRequestBody } from "@/core/interfaces/requests/auth_requests.interface";
import type { ILoginResponse } from "@/core/interfaces/responses/auth-respones.interface";
import { errorHandledTryCatch } from "@/helpers";

class AuthService {
  static login = async (body: ILoginRequestBody) => {
    return errorHandledTryCatch<ILoginResponse>(() =>
      API.post(API_AUTH.LOGIN, body)
    );
  };
}

export { AuthService };
