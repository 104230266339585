<template>
  <div class="radio-button">
    <label :class="`label ${textColor} ${withBorder ? 'border' : ''}`">
      {{ label }}

      <input
        class="input"
        type="radio"
        :id="id"
        :name="name"
        :value="value"
        :checked="checked"
        :disabled="disabled"
        @input="handleClick($event)"
      />

      <span class="indicator"></span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import {
  defaultRadioButtonProps,
  type IRadioButtonEmits,
  type IRadioButtonProps,
} from "@/core/interfaces/components";

const emits = defineEmits<IRadioButtonEmits>();

withDefaults(defineProps<IRadioButtonProps>(), defaultRadioButtonProps);

const handleClick = (event: Event) => {
  emits("on-input", (event.target as HTMLInputElement).value);
};
</script>

<style lang="scss" scoped>
@import "./RadioButton.scss";
</style>
