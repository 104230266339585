export enum CONTENT_LAYOUT_DIRECTION {
  COLUMN = "column",
  ROW = "row",
}

export enum CONTENT_ALIGN {
  STRETCH = "stretch",
  CENTER = "center",
  START = "start",
  END = "end",
}

export enum CONTENT_JUSTIFY {
  START = "start",
  END = "end",
  CENTER = "center",
  SPACE_BETWEEN = "space-between",
  SPACE_AROUND = "space-around",
  SPACE_EVENLY = "space-evenly",
}
