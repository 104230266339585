import { LOCAL_STORAGE_KEYS } from "@/core/enums/common";
import { ROUTER_URLS } from "@/core/enums/router/router_urls.enum";
import type {
  Actions,
  Getters,
  State,
} from "@/core/interfaces/stores/auth_store.interface";
import { setLoginDataInStorage } from "@/helpers";
import { AuthService, LocalStorageService } from "@/services";
import { defineStore } from "pinia";
import { toast, type ToastOptions } from "vue3-toastify";
import router from "@/router";

export const useAuthStore = defineStore<"auth", State, Getters, Actions>(
  "auth",
  {
    state: () => ({
      isAuth: !!LocalStorageService.read(LOCAL_STORAGE_KEYS.AUTH_TOKEN),
      token: LocalStorageService.read(LOCAL_STORAGE_KEYS.AUTH_TOKEN) || "",
      refreshToken:
        LocalStorageService.read(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || "",
      userInfo: null,
    }),
    actions: {
      async login(payload) {
        const response = (await AuthService.login(payload))?.data;

        if (!response) {
          toast("Not successful login", {
            autoClose: 2000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT,
          } as ToastOptions);
          return;
        }

        const { success, data } = response;

        this.isAuth = success;
        this.userInfo = data.user;
        this.token = data.access_token;
        this.refreshToken = data.refresh_token;

        setLoginDataInStorage(data);

        router.push({ path: ROUTER_URLS.PROJECTS });

        setTimeout(() => {
          toast("Successful login", {
            autoClose: 1000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_RIGHT,
          } as ToastOptions);
        }, 1000);
      },

      logout() {
        this.isAuth = false;
        LocalStorageService.clear();
      },
    },
  }
);
