<template>
  <div class="templates">
    <h3 class="templates__title">
      {{ title || "" }}
    </h3>

    <div class="templates__items">
      <div
        class="templates__items-item"
        v-for="{ src, label, id, name, value } in items"
        :key="id"
      >
        <ImageTemplate
          :image="src"
          :is-active-template="selectedTemplate === value"
          @open-modal="() => src && open({ image: src })"
        >
          <RadioButton
            :id="id"
            :name="name"
            :value="value"
            :label="label"
            :checked="selectedTemplate === value"
            :textColor="RADIO_BUTTON_TEXT_COLORS.GRAY"
            @on-input="() => handleChange(value)"
          />
        </ImageTemplate>
      </div>
    </div>
  </div>

  <ImageTemplateModal
    :data="modalData"
    :is-open-modal="isOpen"
    :on-close="close"
  />
</template>

<script setup lang="ts">
import ImageTemplateModal from "@/components/modals/ImageTemplateModal/ImageTemplateModal.vue";
import ImageTemplate from "../ImageTemplate/ImageTemplate.vue";
import RadioButton from "../RadioButton/RadioButton.vue";
import { RADIO_BUTTON_TEXT_COLORS } from "@/core/enums/components";
import type {
  IImageTemplateModalData,
  IImageTemplatesEmits,
  IImageTemplatesProps,
} from "@/core/interfaces/components";
import { useModal } from "@/composables";
import { ref } from "vue";
import type { SelectImageTemplateType } from "@/core/types";

defineProps<IImageTemplatesProps>();
const emit = defineEmits<IImageTemplatesEmits>();

const { isOpen, close, open, modalData } = useModal<IImageTemplateModalData>();

const selectedTemplate = ref<SelectImageTemplateType>(null);

const handleChange = (value: string | number) => {
  selectedTemplate.value = value;
  emit("change", value);
};
</script>

<style scoped lang="scss">
@import "./ImageTemplates.scss";
</style>
