import { INPUTS_STATES_NAME } from "../enums/components";
import { required, minLength } from "@vuelidate/validators";

export const INPUT = {
  LABEL: {
    EMAIL: "Email",
    PASSWORD: "Password",
    NAME: "Name",
    PHONE: "Phone",
    COUNTRY: "Country",
    STATE_REGION: "State/region",
    CITY: "City",
    COMPANY_NAME: "Name Company",
    AREA_ACTIVITY: "Area of activity",
    WEBSITE: "Website",
    GOALS: "Goals",
    WRITE_DOWN:
      "Write down everything you want us to take into account when creating the store",
  },

  PLACEHOLDER: {
    EMAIL: "example@gmail.com",
    PASSWORD: "Enter your password",
    NAME: "Please enter a name",
    STATE_REGION: "Please enter a state or region",
    CITY: "Please enter a city",
    PHONE: "68 123 45 68",
    COUNTRY: "Please enter a country",
    COMPANY: "Please enter a company name",
    AREA_ACTIVITY: "Please enter a area of activity",
    WEBSITE: "Please enter a website name",
    GOALS: "Service sale",
    TYPE: "Type",
  },

  TYPE: {
    TEXT: "text",
    PASSWORD: "password",
    PHONE: "tel",
  },

  ERROR: {
    NOT_VALID_NUMBER: "Not valid number",
  },
};

export const INPUTS_STATE = {
  [INPUTS_STATES_NAME.NAME]: "",
  [INPUTS_STATES_NAME.EMAIL]: "",
  [INPUTS_STATES_NAME.PHONE]: "",
  [INPUTS_STATES_NAME.COUNTRY]: "",
  [INPUTS_STATES_NAME.STATE]: "",
  [INPUTS_STATES_NAME.CITY]: "",
  [INPUTS_STATES_NAME.WEBSITE]: "",
  [INPUTS_STATES_NAME.GOALS]: "",
  [INPUTS_STATES_NAME.AREA_OF_ACTIVITY]: "",
};

export const INPUTS_VALIDATE_RULES = {
  [INPUTS_STATES_NAME.NAME]: { required, minLength: minLength(3) },
  [INPUTS_STATES_NAME.EMAIL]: { required, minLength: minLength(3) },
  [INPUTS_STATES_NAME.PHONE]: { required, minLength: minLength(5) },
  [INPUTS_STATES_NAME.COUNTRY]: { required, minLength: minLength(3) },
  [INPUTS_STATES_NAME.STATE]: { required, minLength: minLength(3) },
  [INPUTS_STATES_NAME.CITY]: { required, minLength: minLength(3) },
  [INPUTS_STATES_NAME.WEBSITE]: { required, minLength: minLength(3) },
  [INPUTS_STATES_NAME.GOALS]: { required, minLength: minLength(3) },
  [INPUTS_STATES_NAME.AREA_OF_ACTIVITY]: { required, minLength: minLength(3) },
};
