export enum ROUTER_URLS {
  HOME = "/dashboard",
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  CLIENTS = "/clients",
  ANALYTIC = "/analytic",
  PROJECTS = "/projects",
  SETTINGS = "/settings",
  FORGOT_PASSWORD = "/forgot-password",
  NEW_PROJECT_CLIENT_INFORMATION = 'client-information',
  NEW_PROJECT_COMPANY_INFORMATION = 'company-information',
  NEW_PROJECT_SERVICES = 'services',
  NEW_PROJECT_ONLINE_STORE = 'online-store',
}
