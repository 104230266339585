import { INPUT } from "../constants";
import { INPUTS_STATES_NAME, INPUT_TYPES } from "../enums/components";
import type { IInputData } from "../interfaces/components";

export const CLIENT_INFO_DATA: IInputData[] = [
  {
    id: 1,
    width: "250px",
    height: "42px",
    value: INPUTS_STATES_NAME.NAME,
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.NAME,
    label: INPUT.LABEL.NAME,
    placeholder: INPUT.PLACEHOLDER.NAME,
  },
  {
    id: 2,
    width: "250px",
    height: "42px",
    value: INPUTS_STATES_NAME.EMAIL,
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.EMAIL,
    label: INPUT.LABEL.EMAIL,
    placeholder: INPUT.PLACEHOLDER.EMAIL,
  },
  {
    id: 3,
    width: "250px",
    height: "42px",
    value: INPUTS_STATES_NAME.PHONE,
    type: INPUT_TYPES.TEL,
    name: INPUTS_STATES_NAME.PHONE,
    label: INPUT.LABEL.PHONE,
    placeholder: INPUT.PLACEHOLDER.PHONE,
  },
  {
    id: 4,
    value: INPUTS_STATES_NAME.COUNTRY,
    width: "250px",
    height: "42px",
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.COUNTRY,
    label: INPUT.LABEL.COUNTRY,
    placeholder: INPUT.PLACEHOLDER.COUNTRY,
  },
  {
    id: 5,
    width: "250px",
    height: "42px",
    value: INPUTS_STATES_NAME.STATE,
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.STATE,
    label: INPUT.LABEL.STATE_REGION,
    placeholder: INPUT.PLACEHOLDER.STATE_REGION,
  },
  {
    id: 6,
    width: "250px",
    height: "42px",
    value: INPUTS_STATES_NAME.CITY,
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.CITY,
    label: INPUT.LABEL.CITY,
    placeholder: INPUT.PLACEHOLDER.CITY,
  },
];
