<template>
  <div
    class="search"
  >
    <img 
      class="search__img"
      :src="magnifier"
      alt="magnifier"
    />

    <input 
      type="text"
      placeholder="Search client, employee"
      :id="id"
      v-debounce:800ms="(val: string) => emit('search', val)"
    >
  </div>
</template>

<script setup lang="ts">
import magnifier from '@/assets/icons/magnifier.svg';
import type { ISearchEmits, ISearchProps } from '@/core/interfaces/components';

defineProps<ISearchProps>();
const emit = defineEmits<ISearchEmits>();
</script>

<style scoped lang="scss">
@import './Search.scss';
</style>