<template>
  <MainLayout v-if="!isSignIn && isAuth">
    <RouterView />
  </MainLayout>

  <AuthLayout v-else>
    <RouterView />
  </AuthLayout>
</template>

<script setup lang="ts">
import MainLayout from "@/layouts/MainLayout/MainLayout.vue";
import AuthLayout from "@/layouts/AuthLayout/AuthLayout.vue";
import { useAuthStore } from "./stores/authStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { ROUTER_URLS } from "./core/enums/router/router_urls.enum";

const route = useRoute();

const { isAuth } = storeToRefs(useAuthStore());

const isSignIn = computed(() => route.path === ROUTER_URLS.SIGN_IN);
</script>

<style scoped lang="scss">
@import "./App.scss";
</style>
