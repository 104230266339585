import { INPUT } from "../constants";
import { INPUTS_STATES_NAME, INPUT_TYPES } from "../enums/components";
import type { IInputData } from "../interfaces/components";

export const COMPANY_INFO_DATA: IInputData[] = [
  {
    id: 1,
    value: INPUTS_STATES_NAME.NAME,
    width: "250px",
    height: "42px",
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.COMPANY_NAME,
    label: INPUT.LABEL.COMPANY_NAME,
    placeholder: INPUT.PLACEHOLDER.COMPANY,
  },
  {
    id: 2,
    value: INPUTS_STATES_NAME.AREA_OF_ACTIVITY,
    width: "250px",
    height: "42px",
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.AREA_OF_ACTIVITY,
    label: INPUT.LABEL.AREA_ACTIVITY,
    placeholder: INPUT.PLACEHOLDER.AREA_ACTIVITY,
  },
  {
    id: 3,
    value: INPUTS_STATES_NAME.WEBSITE,
    width: "250px",
    height: "42px",
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.WEBSITE,
    label: INPUT.LABEL.WEBSITE,
    placeholder: INPUT.PLACEHOLDER.WEBSITE,
  },
  {
    id: 4,
    value: INPUTS_STATES_NAME.COUNTRY,
    width: "250px",
    height: "42px",
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.COUNTRY,
    label: INPUT.LABEL.COUNTRY,
    placeholder: INPUT.PLACEHOLDER.COUNTRY,
  },
  {
    id: 5,
    value: INPUTS_STATES_NAME.STATE,
    width: "250px",
    height: "42px",
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.STATE,
    label: INPUT.LABEL.STATE_REGION,
    placeholder: INPUT.PLACEHOLDER.STATE_REGION,
  },
  {
    id: 6,
    value: INPUTS_STATES_NAME.CITY,
    width: "250px",
    height: "42px",
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.CITY,
    label: INPUT.LABEL.CITY,
    placeholder: INPUT.PLACEHOLDER.CITY,
  },
  {
    id: 7,
    value: INPUTS_STATES_NAME.GOALS,
    width: "250px",
    height: "42px",
    type: INPUT_TYPES.TEXT,
    name: INPUTS_STATES_NAME.GOALS,
    label: INPUT.LABEL.GOALS,
    placeholder: INPUT.PLACEHOLDER.GOALS,
  },
];
