export const TITLE = {
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
  FORGOT_PASSWORD: "Forgot password?",
  NO_ACCOUNT: "Don’t have an account?",
  WELCOME_BACK: "Welcome Back!",
  MANAGE_YOU_DATA: "Efficiently manage your data",
  PAGES: "Pages",
  NOTES: "Notes",
  NOT_FOUND: "Not Found",

  PROJECTS: {
    MAIN_TITLE: "Add New Project",
    CONTENT_TITLE: "Structure of Online Store",
    SECTION_TITLE: {
      HOME_PAGE: {
        TITLE: "Home page",
        WHAT_BLOCKS: "What blocks should be on this page",
        PHONE_NUMBER:
          "Is it necessary to display the phone number in the header of the website?",
      },
      ABOUT_US: {
        TITLE: "About Us",
        WILL_THERE: "Will there be a page about us?",
        VARIANT_TEMPLATE: "Agree on a variant of the about us template",
        NOTES: "Notes",
      },
      CATALOG: {
        TITLE: "Shop/Catalog",
        QUANTITY: "What is the quantity of goods?",
        CATEGORIES: "How many categories?",
        SUBCATEGORIES: "How many subcategories are there?",
        VARIABLE_PRODUCTS: "Are there any variable products?",
        SPECIFICATIONS: "Are there product specifications?",
        PICTURE_FOR_PRODUCTS: "Are there pictures for the products?",
        PICTURES_COUNT: "How many pictures does each product have?",
        DESCRIPTION_GOODS: "Is there a description for the goods?",
        DESCRIPTION_PHOTOS: "Do you need photos in product descriptions?",
        HOW_RECEIVE_GOODS: "How will we receive the goods from the client?",
        PARAMETERS_FILTER:
          "What parameters should you use to filter products in the catalog?",
        SEARCH_PRODUCT: "Do you need to search by product?",

        TEMPLATES: {
          SEARCH_RESULT: "Agree on a variant of the search results template",
          PRODUCT_CATALOG: "Agree on a version of the product catalog template",
          PRODUCT_PAGE: "Agree on a variant of the product page template",
          CART_PAGE: "Agree on a variant of the cart page template",
          CHECKOUT_PAGE: "Agree on a variant of the checkout page template",
          THANK_YOU:
            'Approve the version of the "Thank you for your order" template',
          WISHLIST: "Agree on a version of the wishlist template",
        },
      },
      MARKETING: {
        TITLE: "Email Marketing",
        COLLECT_EMAILS:
          "Do you need to collect emails for further use in marketing? ",
      },
      CRM: {
        TITLE: "CRM",
        USE_CRM: "Does the client use the CRM system?",
        INTEGRATE_CRM: "Do you need to integrate with a CRM system?",
      },
      PAYMENT: {
        TITLE: "Delivery & Payment",
        DELIVERY_TEMPLATE:
          "Approve a variant of the delivery template for your personal account.",
        ONLINE_PAYMENT: "Will there be online payment?",
        DELIVERY_SERVICE: "Is there a delivery service?",
        INTEGRATE_DELIVERY: "Do you need to integrate with a delivery service?",
      },
      PERSONAL_ACCOUNT: {
        TITLE: "Personal Account",
        NEED_PERSONAL_ACCOUNT: "Do you need a personal account for my client?",
        VARIAN_LOGIN:
          "Approve a variant of the login/registration template for your personal account.",
        VERSION_LOGIN:
          "Approve a version of the login template for your personal account.",
        ADDING_ORDERS:
          "Do you need the functionality of adding to Orders? If so, approve the template version.",
        ADDING_ORDER:
          "Do you need the functionality of adding to Single Order? If so, agree on the template version.",
        ADDING_WISHLIST:
          "Do you need the functionality of adding to Wishlist? If so, approve the template version.",
        MY_PROFILE:
          "Do you want to add functionality to My Profile? If so, approve the template version.",
      },
      FAQ: {
        TITLE: "FAQ",
        NEED_FAQ: "Will there be a FAQ page?",
        APPROVE_TEMPLATE: "Will there be a FAQ page?",
        PROVIDE_CONTENT: "Who can provide content for the FAQ?",
      },
      BLOG: {
        TITLE: "Blog & Article",
        NEED_BLOG: "Will there be a Blog on the site?",
        APPROVE_BLOG_TEMPLATE: "Approve the Blog page template",
        APPROVE_ARTICLE_TEMPLATE: "Approve the Article page template",
        PROVIDE_CONTENT: "Who can provide content for the Blog?",
      },
      CONTACT: {
        TITLE: "Contact Us",
        NEED_PAGE: "Will there be a Contact Us page on the site?",
        APPROVE_TEMPLATE: "Approve the Contact Us page template",
      },
      OTHER_PAGES: {
        TITLE: "Other Pages (404, Sitemap, Privacy Policy)",
        APPROVE_PRIVACY: "Approve the Privacy Policy page template",
        PROVIDE_CONTENT: "Who can provide content for the Privacy Policy?",
        APPROVE_SITEMAP: "Approve the Sitemap page template",
        APPROVE_ERROR: "Approve the 404 page template",
      },
      CONTENT: {
        TITLE: "Content",
        WILL_GOODS: "Who will fill the goods?",
        WILL_CONTENT: "Who will fill the site with content?",
        WEBSITE_IMAGES: "Who will provide the images for the website?",
      },
    },
  },
};
