import type { AxiosResponse } from "axios";
import {
  createClient,
  createCompany,
  createProject,
} from "@/stores/actions/project_actions";
import type {
  IProjectPayload,
  TResponseProjectAction,
} from "@/core/interfaces/components/projects.interface";
import type {
  ICreateClientBody,
  ICreateCompanyBody,
  ICreateProjectBody,
} from "@/core/interfaces/requests/project-requests.interface";
import { PROJECT_STEP_NAME } from "@/core/enums/components/projects.enum";
import type { TCommonProjectActionsResponse } from "@/core/types/projects.type";

export const getResponseProjectAction = (
  stepDataBody: IProjectPayload
): Promise<
  AxiosResponse<TCommonProjectActionsResponse, any> | undefined
> | null => {
  const projectStoreAction: {
    [key in PROJECT_STEP_NAME]: TResponseProjectAction | null;
  } = {
    [PROJECT_STEP_NAME.CLIENT_INFO]: () =>
      createClient(stepDataBody.stepData as ICreateClientBody),
    [PROJECT_STEP_NAME.COMPANY_INFO]: () =>
      createCompany(stepDataBody.stepData as ICreateCompanyBody),
    [PROJECT_STEP_NAME.SERVICES]: null,
    [PROJECT_STEP_NAME.ONLINE_STORE]: () =>
      createProject(stepDataBody as ICreateProjectBody),
  };

  const actionFunction = projectStoreAction[stepDataBody.stepInfo.name];

  if (actionFunction) {
    const res: Promise<
      AxiosResponse<TCommonProjectActionsResponse, any> | undefined
    > = actionFunction(stepDataBody.stepData);
    return res;
  }

  return null;
};
