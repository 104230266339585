export const CATALOG_DATA = {
  PRODUCTS: {
    VARIABLE: {
      NAME: "specifications-products",
      DATA: [
        { title: "Yes", value: "Yes", withBorder: true },
        { title: "No", value: "No", withBorder: true },
      ],
    },
    SPECIFICATIONS: {
      NAME: "variable-products",
      DATA: [
        { title: "Yes", value: "Yes", withBorder: true },
        { title: "No", value: "No", withBorder: true },
      ],
    },

    PICTURES: {
      NAME: "pictures-product",
      DATA: [
        { title: "Yes", value: "Yes", withBorder: true },
        { title: "No", value: "No", withBorder: true },
        {
          title: "No, but the client will",
          value: "No, but the client will",
          withBorder: true,
        },
        {
          title: "No, but we need to do",
          value: "No, but we need to do",
          withBorder: true,
        },
      ],
    },
    DESCRIPTION: {
      NAME: "product-description",
      DATA: [
        { title: "Yes", value: "Yes", withBorder: true },
        { title: "No", value: "No", withBorder: true },
      ],
    },
    RECEIVE: {
      NAME: "",
      DATA: [
        {
          title: "There is an Excel table",
          value: "There is an Excel table",
          withBorder: true,
        },
        {
          title: "The client will fill in the Excel table  using our template",
          value: "The client will fill in the Excel table  using our template",
          withBorder: true,
        },

        {
          title: "Extract from an existing customer site",
          value: "Extract from an existing customer site",
          withBorder: true,
        },
      ],
    },
    SEARCH: {
      NAME: "search-product",
      DATA: [
        { title: "Yes", value: "Yes", withBorder: true },
        { title: "No", value: "No", withBorder: true },
      ],
    },
  },
  GOODS: {
    NAME: "description-goods",
    DATA: [
      { title: "Yes", value: "Yes", withBorder: true },
      { title: "No", value: "No", withBorder: true },
      {
        title: "No, but the client will",
        value: "No, but the client will",
        withBorder: true,
      },
      {
        title: "No, but we need to do",
        value: "No, but we need to do",
        withBorder: true,
      },
    ],
  },
};

export const CATALOG_STATE_FILED = {
  goods: "",
  categories: "",
  subCategories: "",
  variableProducts: "No",
  productSpecifications: "No",
  pictureProduct: "No",
  picturesCount: "",
  descriptionGoods: "No",
  productDescription: "No",
  filterParameters: "",
  searchProduct: "No",
  searchTemplate: "",
  productCatalog: "",
  productPage: "",
  cartPage: "",
  checkoutPage: "",
  thanksPage: "",
  wishlistPage: "",
  notes: "",
  receiveProduct: "Extract from an existing customer site",
};
