import { toRaw } from "vue";
import {
  CHECKBOXES_DROPDOWN_LIST_KEYS,
  CHECKBOXES_GROUP_KEYS,
} from "@/core/enums/components/checkboxes.enum";
import type {
  IItemService,
  IServices,
} from "@/core/interfaces/responses/project-responses.interface";
import { SERVICES_TITLES } from "@/core/constants/services.constant";
import type { ICheckboxProps } from "@/core/interfaces/components/checkbox.interface";
import type { IServicesCheckboxState } from "@/core/interfaces/components/services.interface";

export const getTransformedServices = (services: IServicesCheckboxState) => {
  const result = [
    ...Object.values(toRaw(services)).map((item) => item.map(Number)),
  ];
  return result;
};

export const getTransformedServiceData = (services: IServices) => {
  const design = services.design.design.map(({ title, id }) => ({
    title: title,
    value: String(id),
  }));

  const refinement = {
    listTitle: "Refinement",
    listFilterKey: CHECKBOXES_DROPDOWN_LIST_KEYS.REFINEMENT,
    listItems: Object.entries(services.refinement).map(([key, value]) => {
      return {
        title: SERVICES_TITLES[key],
        filterKey: key as CHECKBOXES_GROUP_KEYS,
        items: value.map((item: IItemService) => ({
          title: item.title,
          value: String(item.id),
        })) as ICheckboxProps[],
      };
    }),
  };

  const scratch = {
    listTitle: "Development from scratch",
    listFilterKey: CHECKBOXES_DROPDOWN_LIST_KEYS.FROM_SCRATCH,
    listItems: Object.entries(services.development_from_scratch).map(
      ([key, value]) => {
        return {
          title: SERVICES_TITLES[key],
          filterKey: key as CHECKBOXES_GROUP_KEYS,
          items: value.map((el: IItemService) => ({
            title: el.title,
            value: String(el.id),
          })) as ICheckboxProps[],
        };
      }
    ),
  };

  return {
    design: design,
    refinement: refinement,
    development_from_scratch: scratch,
  };
};
