interface IPhoneControls {
  NAME: string;
  DATA: { title: string; value: boolean; withBorder: boolean }[];
}

export const PHONE_CONTROLS: IPhoneControls = {
  NAME: "phone-controls",
  DATA: [
    { title: "Yes", value: true, withBorder: true },
    { title: "No", value: false, withBorder: true },
  ],
};
