<template>
  <div class="bread-crumbs">
    <!-- Not delete !! <div v-show="breadCrumbItems.length > 1" class="bread-crumbs__button">
      <BackButton />
    </div> -->

    <ul class="bread-crumbs__path">
      <li
        class="bread-crumbs__path-item"
        v-for="item in breadCrumbItems"
        :key="item"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
//import BackButton from "../buttons/BackButton/BackButton.vue";

import { computed } from "vue";
import { useRoute } from "vue-router";
import { getBreadCrumbPath } from "@/helpers";

const route = useRoute();

const breadCrumbItems = computed(() => getBreadCrumbPath(route.path));
</script>

<style scoped lang="scss">
@import "./BreadCrumbs.scss";
</style>
