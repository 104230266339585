import type { IImageTemplate } from "../interfaces/components";
import TestTemplateImage from "@/assets/images/test.png";

export const IMAGE_TEMPLATES_DATA: IImageTemplate[] = [
  {
    id: "1",
    src: TestTemplateImage,
    label: "1 Template",
    value: "1",
    name: "1 Template",
  },
  {
    id: "2",
    src: "",
    label: "2 Template",
    value: "2",
    name: "2 Template",
  },
  {
    id: "3",
    src: TestTemplateImage,
    label: "3 Template",
    value: "3",
    name: "3 Template",
  },
  {
    id: "4",
    src: "",
    label: "4 Template",
    value: "4",
    name: "4 Template",
  },
];
