export enum LOCAL_STORAGE_KEYS {
  USER_ID = "user_id",
  CLIENT_ID = "client_id",
  COMPANY_ID = "company_id",
  TOKEN_TYPE = "token_type",
  AUTH_TOKEN = "access_token",
  TOKEN_VALIDITY = "expires_in",
  REFRESH_TOKEN = "refresh_token",
  PROJECTS_DATA = "projects_data",
}
