import AppContent from "@/components/OnlineStructure/AppContent/AppContent.vue";
import OtherPages from "@/components/OnlineStructure/OtherPages/OtherPages.vue";
import Contact from "@/components/OnlineStructure/Contact/Contact.vue";
import Blog from "@/components/OnlineStructure/Blog/Blog.vue";
import FAQ from "@/components/OnlineStructure/FAQ/FAQ.vue";
import PersonalAccount from "@/components/OnlineStructure/PersonalAccount/PersonalAccount.vue";
import Payment from "@/components/OnlineStructure/Payment/Payment.vue";
import CRM from "@/components/OnlineStructure/CRM/CRM.vue";
import Marketing from "@/components/OnlineStructure/Marketing/Marketing.vue";
import Catalog from "@/components/OnlineStructure/Catalog/Catalog.vue";
import HomePage from "@/components/OnlineStructure/HomePage/HomePage.vue";
import AboutUs from "@/components/OnlineStructure/AboutUs/AboutUs.vue";

import { SECTION_NAMES } from "../enums/components";
import type { IProjectsSections } from "../interfaces/components";

export const PROJECTS_SECTIONS: IProjectsSections[] = [
  {
    value: SECTION_NAMES.HOME,
    component: HomePage,
  },
  {
    value: SECTION_NAMES.SHOP_CATALOG,
    component: Catalog,
  },
  {
    value: SECTION_NAMES.SINGLE_PRODUCT,
    component: Payment,
  },
  {
    value: SECTION_NAMES.SHOP_CATALOG,
    component: CRM,
  },
  {
    value: SECTION_NAMES.CART,
    component: Marketing,
  },
  {
    value: SECTION_NAMES.CHECKOUT,
    component: CRM,
  },
  {
    value: SECTION_NAMES.THANK_YOU,
    component: Catalog,
  },
  {
    value: SECTION_NAMES.WISHLIST,
    component: Catalog,
  },
  {
    value: SECTION_NAMES.GIFT_CARDS,
    component: Catalog,
  },
  {
    value: SECTION_NAMES.SEARCH_RESULT,
    component: Catalog,
  },
  {
    value: SECTION_NAMES.ABOUT_US,
    component: AboutUs,
  },
  {
    value: SECTION_NAMES.FAQ,
    component: FAQ,
  },
  {
    value: SECTION_NAMES.DELIVERY_PAYMENT,
    component: Payment,
  },
  {
    value: SECTION_NAMES.PRIVACY_POLICY,
    component: FAQ,
  },
  {
    value: SECTION_NAMES.CONTACT_US,
    component: Contact,
  },
  {
    value: SECTION_NAMES.BLOG,
    component: Blog,
  },
  {
    value: SECTION_NAMES.ARTICLE_PAGE,
    component: Blog,
  },
  {
    value: SECTION_NAMES.NOT_FOUND,
    component: OtherPages,
  },
  {
    value: SECTION_NAMES.SITEMAP,
    component: AppContent,
  },
  {
    value: SECTION_NAMES.LOGIN_REGISTRATION,
    component: Catalog,
  },
  {
    value: SECTION_NAMES.LOST_PASSWORD,
    component: Catalog,
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT,
    component: PersonalAccount,
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT_ORDERS,
    component: PersonalAccount,
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT_SINGLE_ORDER,
    component: PersonalAccount,
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT_WISH_LIST,
    component: PersonalAccount,
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT_MY_PROFILE,
    component: PersonalAccount,
  },
];
