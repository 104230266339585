export enum BUTTON_BG_COLORS {
  WHITE = "bgWhite",
  ORANGE = "bgOrange",
  GRAY = "bgGray",
}

export enum BUTTON_TEXT_COLORS {
  WHITE = "txtWhite",
  GRAY = "txtGray",
}

export enum BUTTON_BORDER_COLORS {
  ORANGE = "borderOrange",
}
