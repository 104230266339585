<template>
  <DropDownListLayout
    :bg-color="BG_DROPDOWN_LIST_COLORS.GRAY"
    :list-title="TITLE.PROJECTS.SECTION_TITLE.HOME_PAGE.TITLE"
  >
    <div class="online-store__pages--home">
      <div class="online-store__pages--home__blocks">
        <p>{{ TITLE.PROJECTS.SECTION_TITLE.HOME_PAGE.WHAT_BLOCKS }}</p>

        <ul>
          <li v-for="{ title, value } in ONLINE_STORE_HOME_PAGES" :key="value">
            <CheckBox
              :title="title"
              :value="value"
              @change="(value) => handleChangeCheckbox(value)"
            />
          </li>
        </ul>
      </div>

      <ContentLayout
        :content-direction="CONTENT_LAYOUT_DIRECTION.ROW"
        :title="TITLE.PROJECTS.SECTION_TITLE.HOME_PAGE.PHONE_NUMBER"
      >
        <div class="online-store__pages--home__phone--controls">
          <div class="online-store__pages--home__phone--controls__radio-group">
            <RadioButton
              v-for="{ title, value, withBorder } in PHONE_CONTROLS.DATA"
              :id="title"
              :label="title"
              :value="value"
              :with-border="withBorder"
              :name="PHONE_CONTROLS.NAME"
              :checked="isPhoneVisible === value"
              @on-input="() => (isPhoneVisible = value)"
            />
          </div>

          <div
            :class="`online-store__pages--home__phone--controls__phone-input ${
              !isPhoneVisible ? 'disabled' : ''
            }`"
          >
            <PhoneInput
              :value="phoneInputValue"
              @input-clear="() => (phoneInputValue = '')"
              @input-change-event="(value) => (phoneInputValue = String(value))"
            />
          </div>
        </div>
      </ContentLayout>
    </div>
  </DropDownListLayout>
</template>

<script setup lang="ts">
import RadioButton from "@/components/sharable/RadioButton/RadioButton.vue";
import ContentLayout from "@/layouts/ContentLayout/ContentLayout.vue";
import DropDownListLayout from "@/layouts/DropDownListLayout/DropDownListLayout.vue";
import PhoneInput from "@/components/sharable/PhoneInput/PhoneInput.vue";
import CheckBox from "@/components/sharable/CheckBox/CheckBox.vue";

import { TITLE } from "@/core/constants";
import {
  CONTENT_LAYOUT_DIRECTION,
  PROJECT_STEP_NAME,
  SECTION_NAMES,
} from "@/core/enums/components";
import { ONLINE_STORE_HOME_PAGES } from "@/core/constants";
import { BG_DROPDOWN_LIST_COLORS } from "@/core/enums/components";
import { PHONE_CONTROLS } from "@/core/mock-data";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useProjectsStore } from "@/stores/projectsStore";
import { storeToRefs } from "pinia";
import { toRaw } from "vue";
import { validate } from "@/helpers";
import { toast, type ToastOptions } from "vue3-toastify";

const route = useRoute();

const { setProjectData } = useProjectsStore();
const { step } = storeToRefs(useProjectsStore());

const phoneInputValue = ref<string>("");
const checkboxesValues = ref<string[]>([]);

const isPhoneVisible = ref<boolean>(true);

const handleChangeCheckbox = (value: string) => {
  if (checkboxesValues.value.includes(value)) {
    checkboxesValues.value = checkboxesValues.value.filter(
      (checkboxValue: string) => checkboxValue !== value
    );
  }

  checkboxesValues.value.push(value);
};

const handleSubmitData = () => {
  const data = {
    name: "home section",
    blocks: toRaw(checkboxesValues.value),
    ...(isPhoneVisible && { phone: phoneInputValue.value }),
  };

  if (!validate(data)) {
    toast("Not Valid data in Home Section", {
      autoClose: 2000,
      type: toast.TYPE.ERROR,
      position: toast.POSITION.BOTTOM_RIGHT,
    } as ToastOptions);
    return;
  }

  // NEED REWORK

  // const payloadData = {
  //   stepInfo: {
  //     name: PROJECT_STEP_NAME.ONLINE_STORE,
  //     sectionName: SECTION_NAMES.HOME,
  //   },
  //   stepData: data,
  // };

  // setProjectData(payloadData);
};

watch(
  () => step.value,
  (currentValue, _) => {
    if (currentValue?.path === route.path && currentValue.triggerStep) {
      handleSubmitData();
    }
  }
);
</script>

<style scoped lang="scss">
@import "./HomePage.scss";
</style>
