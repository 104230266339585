export const PHONE_INPUT_OPTIONS = {
  DROPDOWN: {
    disabled: false,
    showDialCodeInList: false,
    showDialCodeInSelection: false,
    showFlags: true,
    showSearchBox: false,
    searchBoxPlaceholder: "",
    ignoredCountries: [],
  },
  INPUT: {
    autocomplete: "on",
    autofocus: false,
    id: "",
    maxlength: 25,
    name: "telephone",
    showDialCode: true,
    required: true,
    type: "tel",
    styleClasses: "input",
    mode: "auto",
    validCharactersOnly: true,
  },
};
