import "./style.scss";

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { vueDebounce } from "vue-debounce";
import VueTelInput from "vue-tel-input";
import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";

import "vue3-toastify/dist/index.css";
import "vue-tel-input/vue-tel-input.css";

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(Vue3Toasity, {
  autoClose: 3000,
} as ToastContainerOptions);
app.directive("debounce", vueDebounce({ trim: true, lock: true }));
//@ts-ignore
app.use(VueTelInput);

app.mount("#app");
