import type { IErrorResponse } from "@/core/interfaces/stores/common.interface";
import { useSystemStore } from "@/stores/systemStore";
import type { AxiosError, AxiosResponse } from "axios";

export const errorHandledTryCatch = async <T>(
  f: () => Promise<AxiosResponse<T> | undefined>
) => {
  const systemStore = useSystemStore();

  try {
    return await f();
  } catch (error) {
    const msg =
      (error as AxiosError<IErrorResponse>).response?.data.errors ||
      (error as AxiosError<IErrorResponse>).message ||
      "Something went wrong";

    systemStore.setErrorMessages(msg);
  }
};
