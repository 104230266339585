export const SERVICES_TITLES: Record<string, string> = {
  corporate_website: "Corporate Website",
  e_commerce: "E-Commerce",
  market_place: "Market Place",
  mobile_app: "Mobile App",
  framework: "Framework",
  crm: "CRM",
  erp: "ERP",
  landing_page: "Landing Page",
};
