import type {
  Actions,
  Getters,
  State,
} from "@/core/interfaces/stores/system_store.interface";
import { defineStore } from "pinia";

export const useSystemStore = defineStore<"system", State, Getters, Actions>(
  "system",
  {
    state: () => ({
      isLoading: false,
      errorMessage: "",
      hideLoader: false,
    }),
    getters: {
      getIsErrorShow() {
        return !!this.errorMessage.length;
      },
    },
    actions: {
      setIsLoading(value) {
        this.isLoading = value;
      },
      setHideLoader(val) {
        this.hideLoader = val;
      },
      setErrorMessages(message) {
        this.errorMessage = message;

        setTimeout(this.clearErrorMessage, 6000);
      },
      clearErrorMessage() {
        this.errorMessage = "";
      },
    },
  }
);
