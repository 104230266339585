// vue
import type { ICON_TYPE } from "@/core/enums/components";
import { INPUT_TYPES } from "@/core/enums/components";

import type { Ref } from "vue";

export const customInputDefaultProps = {
  value: "",
  label: "",
  type: INPUT_TYPES.TEXT,
  iconType: undefined,

  placeholder: "",
  errorMessage: "",
  inputHeight: "100%",

  onBlur: () => {},
  onFocus: () => {},
  onClick: () => {},
  handleHideText: () => {},
};

export interface ICustomInputProps {
  type?: INPUT_TYPES;
  value: string | number;
  label?: string;
  iconType?: ICON_TYPE;

  placeholder?: string;
  inputHeight?: string;
  errorMessage?: string | Ref<string>;

  onBlur?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  handleHideText?: () => void;
}

export interface IInputEmits {
  (e: "input-change-event", value: string | number): void;
  (e: "input-icon-click"): void;
}
