import type {
  ICheckboxProps,
  IDropdownCheckboxList,
} from "@/core/interfaces/components";
import {
  CHECKBOXES_DROPDOWN_LIST_KEYS,
  CHECKBOXES_GROUP_KEYS,
} from "../enums/components";

export const CLIENT_INFO_COMMUNICATION_GROUP: ICheckboxProps[] = [
  {
    title: "Fiverr",
    value: "fiverr",
  },
  {
    title: "Upwork",
    value: "upwork",
  },
  {
    title: "Clutch",
    value: "clutch",
  },
  {
    title: "Linkedin",
    value: "inkedin",
  },
  {
    title: "Telegram",
    value: "telegram",
  },
  {
    title: "What’s Up",
    value: "wats_up",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const CLIENT_INFO_HOW_ABOUT_GROUP: ICheckboxProps[] = [
  {
    title: "Fiverr",
    value: "fiverr",
  },
  {
    title: "Upwork",
    value: "upwork",
  },
  {
    title: "Clutch",
    value: "clutch",
  },
  {
    title: "Linkedin",
    value: "linkedin",
  },
  {
    title: "Partners",
    value: "parameters",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const SERVICES_DESIGN_GROUP: ICheckboxProps[] = [
  {
    title: "Brand Book Design",
    value: "brand_book_design",
  },
  {
    title: "Logo Design",
    value: "logo_design",
  },
  {
    title: "Web Design",
    value: "web_design",
  },
  {
    title: "Mobile App Design",
    value: "mobile_app-design",
  },
  {
    title: "Poster Design",
    value: "poster_design",
  },
  {
    title: "Social Media Design",
    value: "social_media_design",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const SERVICES_CRM_ERP_GROUP: ICheckboxProps[] = [
  {
    title: "Laravel + Vue",
    value: "laravel+vue",
  },
  {
    title: "Laravel + React",
    value: "laravel+react",
  },
  {
    title: "Phyton + Vue",
    value: "phyton+vue",
  },
  {
    title: "Phyton + React",
    value: "phyton+react",
  },
  {
    title: "C++ + Vue",
    value: "c+++vue",
  },
  {
    title: "C++ + React",
    value: "c+++react",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const SERVICES_E_COMMERCE_MARKETPLACE_GROUP: ICheckboxProps[] = [
  {
    title: "Wordpress + Woocommerce",
    value: "wordpress+woocommerce",
  },
  {
    title: "Shopify",
    value: "shopify",
  },
  {
    title: "Laravel + Vue",
    value: "laravel+vue",
  },
  {
    title: "Laravel + React",
    value: "laravel+react",
  },
  {
    title: "Phyton + Vue",
    value: "phyton+vue",
  },
  {
    title: "Phyton + React",
    value: "phyton+react",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const SERVICES_CORPORATE_LANDING_GROUP: ICheckboxProps[] = [
  {
    title: "Wordpress",
    value: "wordpress",
  },
  {
    title: "Laravel + Vue",
    value: "laravel+vue",
  },
  {
    title: "Laravel + React",
    value: "laravel+react",
  },
  {
    title: "Phyton + Vue",
    value: "phyton+vue",
  },
  {
    title: "Phyton + React",
    value: "phyton+react",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const SERVICES_MOBILE_GROUP: ICheckboxProps[] = [
  {
    title: "IOS",
    value: "ios",
  },
  {
    title: "Android",
    value: "android",
  },
  {
    title: "Native",
    value: "native",
  },
  {
    title: "Ionic",
    value: "ionic",
  },
  {
    title: "Flutter",
    value: "flutter",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const ONLINE_STORE_HOME_PAGES: ICheckboxProps[] = [
  {
    title: "Banner",
    value: "1",
  },
  {
    title: "Benefits",
    value: "2",
  },
  {
    title: "About Us",
    value: "3",
  },
  {
    title: "Product Categories",
    value: "4",
  },
  {
    title: "Popular Products",
    value: "5",
  },
  {
    title: "Product Selection",
    value: "6",
  },
  {
    title: "Reviews",
    value: "7",
  },
  {
    title: "Contact",
    value: "8",
  },
];

export const SERVICES_DROPDOWN_REFINEMENT: IDropdownCheckboxList = {
  listTitle: "Refinement",
  listFilterKey: CHECKBOXES_DROPDOWN_LIST_KEYS.REFINEMENT,
  listItems: [
    {
      title: "Corporate Website",
      filterKey: CHECKBOXES_GROUP_KEYS.CORPORATE_SITE,
      items: SERVICES_CORPORATE_LANDING_GROUP,
    },
    {
      title: "E-Commerce",
      filterKey: CHECKBOXES_GROUP_KEYS.E_COMMERCE,
      items: SERVICES_E_COMMERCE_MARKETPLACE_GROUP,
    },
    {
      title: "Market Place",
      filterKey: CHECKBOXES_GROUP_KEYS.MARKET_PLACE,
      items: SERVICES_E_COMMERCE_MARKETPLACE_GROUP,
    },
    {
      title: "Mobile App",
      filterKey: CHECKBOXES_GROUP_KEYS.MOBILE_APP,
      items: SERVICES_MOBILE_GROUP,
    },
    {
      title: "CRM",
      filterKey: CHECKBOXES_GROUP_KEYS.CRM,
      items: SERVICES_CRM_ERP_GROUP,
    },
    {
      title: "ERP",
      filterKey: CHECKBOXES_GROUP_KEYS.ERP,
      items: SERVICES_CRM_ERP_GROUP,
    },
    {
      title: "Landing Page",
      filterKey: CHECKBOXES_GROUP_KEYS.LANDING_PAGE,
      items: SERVICES_CORPORATE_LANDING_GROUP,
    },
  ],
};

export const SERVICES_DROPDOWN_FROM_SCRATCH: IDropdownCheckboxList = {
  listTitle: "Development from scratch",
  listFilterKey: CHECKBOXES_DROPDOWN_LIST_KEYS.FROM_SCRATCH,
  listItems: [
    {
      title: "Corporate Website",
      filterKey: CHECKBOXES_GROUP_KEYS.CORPORATE_SITE,
      items: SERVICES_CORPORATE_LANDING_GROUP,
    },
    {
      title: "E-Commerce",
      filterKey: CHECKBOXES_GROUP_KEYS.E_COMMERCE,
      items: SERVICES_E_COMMERCE_MARKETPLACE_GROUP,
    },
    {
      title: "Market Place",
      filterKey: CHECKBOXES_GROUP_KEYS.MARKET_PLACE,
      items: SERVICES_E_COMMERCE_MARKETPLACE_GROUP,
    },
    {
      title: "Mobile App",
      filterKey: CHECKBOXES_GROUP_KEYS.MOBILE_APP,
      items: SERVICES_MOBILE_GROUP,
    },
    {
      title: "CRM",
      filterKey: CHECKBOXES_GROUP_KEYS.CRM,
      items: SERVICES_CRM_ERP_GROUP,
    },
    {
      title: "ERP",
      filterKey: CHECKBOXES_GROUP_KEYS.ERP,
      items: SERVICES_CRM_ERP_GROUP,
    },
    {
      title: "Landing Page",
      filterKey: CHECKBOXES_GROUP_KEYS.LANDING_PAGE,
      items: SERVICES_CORPORATE_LANDING_GROUP,
    },
  ],
};
