import { API } from "@/api/api";
import {
  API_CLIENT,
  API_COMPANY,
  API_PROJECT,
  API_PROJECT_SECTIONS,
  API_SERVICES,
} from "@/core/constants";
import type {
  ICreateClientBody,
  ICreateCompanyBody,
  ICreateProjectBody,
  IUpdateClientBody,
} from "@/core/interfaces/requests/project-requests.interface";
import type {
  ICreateClientResponse,
  ICreateCompanyResponse,
  IServicesResponse,
} from "@/core/interfaces/responses/project-responses.interface";
import { errorHandledTryCatch } from "@/helpers";

class ProjectService {
  static createProject = async (body: ICreateProjectBody) => {
    return errorHandledTryCatch<any>(() => API.post(API_PROJECT.CREATE, body));
  };

  static createClient = async (body: ICreateClientBody) => {
    return errorHandledTryCatch<ICreateClientResponse>(() =>
      API.post(API_CLIENT.CREATE, body)
    );
  };

  static updateClient = async (clientId: string, body: IUpdateClientBody) => {
    return errorHandledTryCatch<any>(() =>
      API.post(API_CLIENT.UPDATE(clientId), body)
    );
  };

  static getAllClients = async () => {
    return errorHandledTryCatch<any>(() => API.get(API_CLIENT.GET_ALL));
  };

  static getClientById = async (clientId: string) => {
    return errorHandledTryCatch<any>(() =>
      API.get(API_CLIENT.GET_BY_ID(clientId))
    );
  };

  static createCompany = async (body: ICreateCompanyBody) => {
    return errorHandledTryCatch<ICreateCompanyResponse>(() =>
      API.post(API_COMPANY.CREATE, body)
    );
  };

  static getServices = async () => {
    return errorHandledTryCatch<IServicesResponse>(() =>
      API.get(API_SERVICES.GET)
    );
  };

  static getProjectSections = async (servicesIds: number[]) => {
    return errorHandledTryCatch<any>(() =>
      API.get(API_PROJECT_SECTIONS.GET(servicesIds))
    );
  };
}

export { ProjectService };
