<template>
  <DropDownListLayout
    :bg-color="BG_DROPDOWN_LIST_COLORS.GRAY"
    :list-title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.TITLE"
  >
    <div class="online-store__pages--catalog">
      <ContentLayout :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.QUANTITY">
        <div class="online-store__pages--catalog__quantity-goods">
          <CustomInput
            :type="INPUT_TYPES.NUMBER"
            :value="catalogState.goods"
            :placeholder="INPUT.PLACEHOLDER.TYPE"
            @input-change-event="
              (value) => (catalogState.goods = value as string)
            "
          />
        </div>
      </ContentLayout>

      <ContentLayout :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.CATEGORIES">
        <div class="online-store__pages--catalog__categories">
          <div class="online-store__pages--catalog__categories-type">
            <CustomInput
              ref="inputRef"
              :type="INPUT_TYPES.NUMBER"
              :value="catalogState.categories"
              :placeholder="INPUT.PLACEHOLDER.TYPE"
              @input-change-event="
                (value) => handleInputChange(value as string, INPUT_TYPE.CATEGORIES)
              "
            />
          </div>
          <div class="online-store__pages--catalog__categories-radio-btn">
            <RadioButton
              id="1"
              name="categories"
              :with-border="true"
              label="No categories"
              :checked="!catalogState.categories"
              @on-input="() => clearInput(INPUT_TYPE.CATEGORIES)"
            />
          </div>
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.SUBCATEGORIES"
      >
        <div class="online-store__pages--catalog__subcategories">
          <div class="online-store__pages--catalog__subcategories-type">
            <CustomInput
              ref="inputRefSub"
              :type="INPUT_TYPES.NUMBER"
              :value="catalogState.subCategories"
              :placeholder="INPUT.PLACEHOLDER.TYPE"
              @input-change-event="
                (value) => handleInputChange(value as string, INPUT_TYPE.SUB_CATEGORIES)
              "
            />
          </div>
          <div class="online-store__pages--catalog__subcategories-radio-btn">
            <RadioButton
              id="2"
              name="sub-categories"
              :with-border="true"
              label="No categories"
              :checked="!catalogState.subCategories"
              @on-input="() => clearInput(INPUT_TYPE.SUB_CATEGORIES)"
            />
          </div>
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.VARIABLE_PRODUCTS"
      >
        <div class="online-store__pages--catalog__variable-products">
          <RadioButton
            v-for="{ title, value, withBorder } in CATALOG_DATA.PRODUCTS
              .VARIABLE.DATA"
            :id="title"
            :label="title"
            :value="value"
            :with-border="withBorder"
            :name="CATALOG_DATA.PRODUCTS.VARIABLE.NAME"
            :checked="catalogState.variableProducts === value"
            @on-input="() => (catalogState.variableProducts = value)"
          />
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.SPECIFICATIONS"
      >
        <div class="online-store__pages--catalog__specifications">
          <RadioButton
            v-for="{ title, value, withBorder } in CATALOG_DATA.PRODUCTS
              .SPECIFICATIONS.DATA"
            :id="title"
            :label="title"
            :value="value"
            :with-border="withBorder"
            :name="CATALOG_DATA.PRODUCTS.SPECIFICATIONS.NAME"
            :checked="catalogState.productSpecifications === value"
            @on-input="() => (catalogState.productSpecifications = value)"
          />
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.PICTURE_FOR_PRODUCTS"
      >
        <div class="online-store__pages--catalog__pictures-products">
          <RadioButton
            v-for="{ title, value, withBorder } in CATALOG_DATA.PRODUCTS
              .PICTURES.DATA"
            :id="title"
            :label="title"
            :value="value"
            :with-border="withBorder"
            :name="CATALOG_DATA.PRODUCTS.PICTURES.NAME"
            :checked="catalogState.pictureProduct === value"
            @on-input="() => (catalogState.pictureProduct = value)"
          />
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.PICTURES_COUNT"
      >
        <div class="online-store__pages--catalog__pictures-count">
          <CustomInput
            :type="INPUT_TYPES.NUMBER"
            :value="catalogState.picturesCount"
            :placeholder="INPUT.PLACEHOLDER.TYPE"
            @input-change-event="
              (value) => (catalogState.picturesCount = value as string)
            "
          />
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.DESCRIPTION_GOODS"
      >
        <div class="online-store__pages--catalog__pictures-products">
          <RadioButton
            v-for="{ title, value, withBorder } in CATALOG_DATA.GOODS.DATA"
            :id="title"
            :label="title"
            :value="value"
            :with-border="withBorder"
            :name="CATALOG_DATA.GOODS.NAME"
            :checked="catalogState.descriptionGoods === value"
            @on-input="() => (catalogState.descriptionGoods = value)"
          />
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.DESCRIPTION_PHOTOS"
      >
        <div class="online-store__pages--catalog__description-photos">
          <RadioButton
            v-for="{ title, value, withBorder } in CATALOG_DATA.PRODUCTS
              .DESCRIPTION.DATA"
            :id="title"
            :label="title"
            :value="value"
            :with-border="withBorder"
            :name="CATALOG_DATA.PRODUCTS.DESCRIPTION.NAME"
            :checked="catalogState.productDescription === value"
            @on-input="() => (catalogState.productDescription = value)"
          />
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.HOW_RECEIVE_GOODS"
      >
        <div class="online-store__pages--catalog__receive-goods">
          <RadioButton
            v-for="{ title, value, withBorder } in CATALOG_DATA.PRODUCTS.RECEIVE
              .DATA"
            :id="title"
            :label="title"
            :value="value"
            :with-border="withBorder"
            :name="CATALOG_DATA.PRODUCTS.RECEIVE.NAME"
            :checked="catalogState.receiveProduct === value"
            @on-input="() => (catalogState.receiveProduct = value)"
          />
        </div>
      </ContentLayout>

      <ContentLayout
        :content-align="CONTENT_ALIGN.START"
        :content-direction="CONTENT_LAYOUT_DIRECTION.COLUMN"
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.PARAMETERS_FILTER"
      >
        <div class="online-store__pages--catalog__parameters-filter">
          <CustomInput
            :value="catalogState.filterParameters"
            :type="INPUT_TYPES.TEXT"
            :placeholder="INPUT.PLACEHOLDER.TYPE"
            @input-change-event="(value) => (catalogState.filterParameters = value as string)"
          />
        </div>
      </ContentLayout>

      <ContentLayout
        :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.SEARCH_PRODUCT"
      >
        <div class="online-store__pages--catalog__search-product">
          <RadioButton
            v-for="{ title, value, withBorder } in CATALOG_DATA.PRODUCTS.SEARCH
              .DATA"
            :id="title"
            :label="title"
            :value="value"
            :with-border="withBorder"
            :name="CATALOG_DATA.PRODUCTS.SEARCH.NAME"
            :checked="catalogState.searchProduct === value"
            @on-input="() => (catalogState.searchProduct = value)"
          />
        </div>
      </ContentLayout>

      <div class="online-store__pages--catalog__search-result">
        <ImageTemplates
          :items="IMAGE_TEMPLATES_DATA"
          :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.TEMPLATES.SEARCH_RESULT"
          @change="(value) => (catalogState.searchTemplate = value as string)"
        />
      </div>

      <div class="online-store__pages--catalog__product-catalog">
        <ImageTemplates
          :items="IMAGE_TEMPLATES_DATA"
          :title="
            TITLE.PROJECTS.SECTION_TITLE.CATALOG.TEMPLATES.PRODUCT_CATALOG
          "
          @change="(value) => (catalogState.productCatalog = value as string)"
        />
      </div>

      <div class="online-store__pages--catalog__product-page">
        <ImageTemplates
          :items="IMAGE_TEMPLATES_DATA"
          :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.TEMPLATES.PRODUCT_PAGE"
          @change="(value) => (catalogState.productPage = value as string)"
        />
      </div>

      <div class="online-store__pages--catalog__cart-page">
        <ImageTemplates
          :items="IMAGE_TEMPLATES_DATA"
          :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.TEMPLATES.CART_PAGE"
          @change="(value) => (catalogState.cartPage = value as string)"
        />
      </div>

      <div class="online-store__pages--catalog__checkout-page">
        <ImageTemplates
          :items="IMAGE_TEMPLATES_DATA"
          :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.TEMPLATES.CHECKOUT_PAGE"
          @change="(value) => (catalogState.checkoutPage = value as string)"
        />
      </div>

      <div class="online-store__pages--catalog__thank-you">
        <ImageTemplates
          :items="IMAGE_TEMPLATES_DATA"
          :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.TEMPLATES.THANK_YOU"
          @change="(value) => (catalogState.thanksPage = value as string)"
        />
      </div>

      <div class="online-store__pages--catalog__wishlist">
        <ImageTemplates
          :items="IMAGE_TEMPLATES_DATA"
          :title="TITLE.PROJECTS.SECTION_TITLE.CATALOG.TEMPLATES.WISHLIST"
          @change="(value) => (catalogState.wishlistPage = value as string)"
        />
      </div>

      <ContentLayout
        :title="TITLE.NOTES"
        :content-align="CONTENT_ALIGN.START"
        :content-direction="CONTENT_LAYOUT_DIRECTION.COLUMN"
      >
        <div class="online-store__pages--catalog__notes">
          <CustomInput
            :value="catalogState.notes"
            :type="INPUT_TYPES.TEXT"
            :placeholder="INPUT.PLACEHOLDER.TYPE"
            :label="INPUT.LABEL.WRITE_DOWN"
            @input-change-event="(value) => (catalogState.notes = value as string)"
          />
        </div>
      </ContentLayout>
    </div>
  </DropDownListLayout>
</template>

<script setup lang="ts">
import ImageTemplates from "@/components/sharable/ImageTemplates/ImageTemplates.vue";
import CustomInput from "@/components/sharable/CustomInput/CustomInput.vue";
import RadioButton from "@/components/sharable/RadioButton/RadioButton.vue";
import ContentLayout from "@/layouts/ContentLayout/ContentLayout.vue";
import DropDownListLayout from "@/layouts/DropDownListLayout/DropDownListLayout.vue";

import { INPUT } from "@/core/constants";
import { TITLE } from "@/core/constants";
import {
  CONTENT_ALIGN,
  INPUT_TYPE,
  PROJECT_STEP_NAME,
  SECTION_NAMES,
} from "@/core/enums/components";
import { INPUT_TYPES } from "@/core/enums/components";
import { CATALOG_STATE_FILED, IMAGE_TEMPLATES_DATA } from "@/core/mock-data";
import { CONTENT_LAYOUT_DIRECTION } from "@/core/enums/components";
import { BG_DROPDOWN_LIST_COLORS } from "@/core/enums/components";
import { reactive, ref, toRaw, watch } from "vue";
import { CATALOG_DATA } from "@/core/mock-data";
import { useProjectsStore } from "@/stores/projectsStore";
import { storeToRefs } from "pinia";
import { validate } from "@/helpers";
import { useRoute } from "vue-router";
import { toast, type ToastOptions } from "vue3-toastify";

const route = useRoute();

const { setProjectData } = useProjectsStore();
const { step } = storeToRefs(useProjectsStore());

const catalogState = reactive(CATALOG_STATE_FILED);

const inputRef = ref<InstanceType<typeof CustomInput> | null>(null);
const inputRefSub = ref<InstanceType<typeof CustomInput> | null>(null);

const handleInputChange = (value: string, inputType: string) => {
  switch (inputType) {
    case INPUT_TYPE.CATEGORIES:
      catalogState.categories = value;
      break;
    case INPUT_TYPE.SUB_CATEGORIES:
      catalogState.subCategories = value;
      break;
    default:
      catalogState.subCategories = "";
      catalogState.categories = "";
  }
};

const clearInput = (inputType: INPUT_TYPE) => {
  const inputs = {
    [INPUT_TYPE.CATEGORIES]: {
      state: catalogState.categories,
      ref: inputRef.value,
    },
    [INPUT_TYPE.SUB_CATEGORIES]: {
      state: catalogState.subCategories,
      ref: inputRefSub.value,
    },
  };
  inputs[inputType].state = "";
  inputs[inputType].ref?.clear();
};

const handleSubmitData = () => {
  const data = {
    name: "catalog section",
    ...toRaw(catalogState),
  };

  if (!validate(data)) {
    toast("Not Valid data in Catalog Section", {
      autoClose: 2000,
      type: toast.TYPE.ERROR,
      position: toast.POSITION.BOTTOM_RIGHT,
    } as ToastOptions);
    return;
  }

  // NEED REWORK

  // const payloadData = {
  //   stepInfo: {
  //     name: PROJECT_STEP_NAME.ONLINE_STORE,
  //     sectionName: SECTION_NAMES.SHOP_CATALOG,
  //   },
  //   stepData: data,
  // };

  // setProjectData(payloadData);
};

watch(
  () => step.value,
  (currentValue, _) => {
    if (currentValue?.path === route.path && currentValue.triggerStep) {
      handleSubmitData();
    }
  }
);
</script>

<style scoped lang="scss">
@import "./Catalog.scss";
</style>
