export const validate = (data: Record<string, any>): boolean => {
  return Object.entries(data).every(([_, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === "string") {
      return value.length > 0;
    } else if (typeof value === "object" && value !== null) {
      return Object.keys(value).length > 0;
    } else {
      return !!value;
    }
  });
};
