<template>
  <div :class="`template ${isActiveTemplate ? 'template__is-active' : ''}`">
    <div
      class="template__image"
      :style="{ backgroundImage: `url('${image}')` }"
    >
      <div class="template__image-icon">
        <CustomIcon
          :icon-type="ICON_TYPE.TEMPLATE"
          @click="() => emit('open-modal')"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ICON_TYPE } from "@/core/enums/components";
import CustomIcon from "../CustomIcon/CustomIcon.vue";
import type {
  IImageTemplateEmits,
  IImageTemplateProps,
} from "@/core/interfaces/components";

defineProps<IImageTemplateProps>();
const emit = defineEmits<IImageTemplateEmits>();
</script>

<style scoped lang="scss">
@import "./ImageTemplate.scss";
</style>
