<template>
  <div class="wrapper">
    <div v-show="!!label" class="label">{{ label }}</div>

    <input
      class="input"
      v-model="inputValue"
      :type="type"
      :height="inputHeight"
      :placeholder="placeholder"
      autocomplete="off"
      @reset="clear"
      @blur="onBlur"
      @click="onClick"
      @focusout="onFocus"
      @input="() => emit('input-change-event', inputValue)"
    />

    <CustomIcon
      v-if="!!iconType"
      class-name="input__img"
      :icon-type="iconType"
      @click="() => emit('input-icon-click')"
    />

    <p v-show="!!errorMessage" class="input__error">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import {
  customInputDefaultProps,
  type ICustomInputProps,
  type IInputEmits,
} from "@/core/interfaces/components";

import CustomIcon from "../CustomIcon/CustomIcon.vue";

const emit = defineEmits<IInputEmits>();
const { value } = withDefaults(
  defineProps<ICustomInputProps>(),
  customInputDefaultProps
);

const inputValue = ref(value);

const clear = () => {
  inputValue.value = "";
};

defineExpose({
  clear,
});
</script>

<style lang="scss">
@import "./CustomInput.scss";
</style>
