import type { SelectImageTemplateType } from "@/core/types";

export interface IRadioButtonEmits {
  (e: "on-input", value: any): void;
}

export interface IRadioButtonProps {
  textColor?: string;
  name: string;
  id: string;
  value: SelectImageTemplateType;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  group?: boolean;
  withBorder?: boolean;
}

export const defaultRadioButtonProps = {
  withBorder: false,
  textColor: "",
  name: "",
  id: "",
  value: "",
  label: "",
  checked: false,
  disabled: false,
  group: false,
};
