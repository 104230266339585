import type {
  Actions,
  Getters,
  State,
} from "@/core/interfaces/stores/projects_store.interface";
import router from "@/router";
import { API } from "@/api/api";
import { defineStore } from "pinia";
import type { AxiosError } from "axios";
import { useSystemStore } from "./systemStore";
import { toast, type ToastOptions } from "vue3-toastify";
import { LocalStorageService, ProjectService } from "@/services";
import { ROUTER_URLS } from "@/core/enums/router/router_urls.enum";
import {
  API_SERVICES,
  DEFAULT_PROJECT_DATA,
  PROJECTS_SECTIONS,
} from "@/core/constants";
import type {
  ICreateServicesBody,
  IProjectPayloadStepDataType,
  IProjectsSections,
} from "@/core/interfaces/components";
import { stepConfig, subPathArray } from "@/core/mock-data/projects.data";
import { LOCAL_STORAGE_KEYS } from "@/core/enums/common/local_storage.enum";
import type { IErrorResponse } from "@/core/interfaces/stores/common.interface";
import { recursiveKeyTransformation } from "@/helpers/recursive_key_transformation";
import { getTransformedServiceData } from "@/helpers/transform_services.interface";

import type {
  IServices,
  IServicesResponse,
} from "@/core/interfaces/responses/project-responses.interface";
import { getResponseProjectAction } from "@/helpers/get_responses_project_page";
import { toRaw } from "vue";
import { PROJECT_STEP_NAME } from "@/core/enums/components/projects.enum";
import type {
  ICreateClientBody,
  ICreateCompanyBody,
  ICreateProjectBody,
} from "@/core/interfaces/requests/project-requests.interface";

export const useProjectsStore = defineStore<
  "projects",
  State,
  Getters,
  Actions
>("projects", {
  state: () => ({
    step: null,
    services: null,
    currentStep: 1,
    activeSections: [],
    projectData: DEFAULT_PROJECT_DATA,
    sections: { pages: [], site_blocks: [] },
  }),
  actions: {
    manageSection(sectionName) {
      const currentSection: IProjectsSections = PROJECTS_SECTIONS.find(
        ({ value }) => value === sectionName
      )!;

      if (this.activeSections.includes(currentSection)) {
        const idx = this.activeSections.findIndex(
          ({ value }) => value === sectionName
        );

        this.activeSections.splice(idx, 1);

        return;
      }
      this.activeSections.push(currentSection);
    },

    takeStep(isForward) {
      if (isForward) {
        this.step = { ...stepConfig[this.currentStep], triggerStep: true };
      }

      if (!isForward && this.currentStep > 1) {
        this.currentStep -= 1;

        router.push({
          path: ROUTER_URLS.PROJECTS + "/" + subPathArray[this.currentStep - 1],
          query: {
            step: this.currentStep,
          },
        });

        return;
      }
    },

    async getServices() {
      const systemStore = useSystemStore();

      try {
        const servicesResponse: IServicesResponse | undefined = (
          await API.get(API_SERVICES.GET)
        )?.data;

        if (servicesResponse) {
          this.services = getTransformedServiceData(
            recursiveKeyTransformation(servicesResponse) as IServices
          );
        }
      } catch (error) {
        const msg =
          (error as AxiosError<IErrorResponse>).response?.data.errors ||
          (error as AxiosError<IErrorResponse>).message ||
          "Something went wrong";

        systemStore.setErrorMessages(msg);
      }
    },

    async getProjectSections() {
      // Need to pass the id array from the services (currently, it does not work and returns empty arrays in the response).
      if (!!this.projectData.SERVICES?.services.length) {
        const { services } = this.projectData.SERVICES;

        const response = await ProjectService.getProjectSections(
          toRaw(services)
        );

        if (!response) {
          toast("Response Error", {
            autoClose: 2000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT,
          } as ToastOptions);
          return;
        }

        this.sections = response.data;
      }
    },

    async setProjectData(payload) {
      const response = await getResponseProjectAction(payload);

      if (!response?.data && response !== null) {
        toast("Response Error", {
          autoClose: 2000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        } as ToastOptions);
        return;
      }

      switch (payload.stepInfo.name) {
        case PROJECT_STEP_NAME.CLIENT_INFO:
          this.projectData.CLIENT_INFO = payload.stepData as ICreateClientBody;

          break;
        case PROJECT_STEP_NAME.COMPANY_INFO:
          this.projectData.COMPANY_INFO =
            payload.stepData as ICreateCompanyBody;

          break;
        case PROJECT_STEP_NAME.SERVICES:
          this.projectData.SERVICES = payload.stepData as ICreateServicesBody;

          break;
        case PROJECT_STEP_NAME.ONLINE_STORE:
          this.projectData.ONLINE_STORE =
            payload.stepData as ICreateProjectBody;

          break;
      }

      if (response?.data) {
        LocalStorageService.set(
          Object.entries(response.data)[0][0] as LOCAL_STORAGE_KEYS,
          Object.entries(response.data)[0][1]
        );
      }

      if (this.currentStep < 4) {
        this.currentStep += 1;

        router.push({
          path: ROUTER_URLS.PROJECTS + "/" + payload.stepRoute,
          query: {
            step: this.currentStep,
          },
        });
      }
    },
  },
});
