// STEPS
export enum PROJECT_STEP_NAME {
  SERVICES = "SERVICES",
  CLIENT_INFO = "CLIENT_INFO",
  COMPANY_INFO = "COMPANY_INFO",
  ONLINE_STORE = "ONLINE_STORE",
}

// SECTION
export enum SECTION_NAMES {
  FAQ = "faq",
  HOME = "home",
  CART = "cart",
  BLOG = "blog",
  NOT_FOUND = "404",
  SITEMAP = "sitemap",
  CHECKOUT = "checkout",
  WISHLIST = "wishlist",
  ABOUT_US = "about_us",
  THANK_YOU = "thank_you",
  CONTACT_US = "contact_us",
  GIFT_CARDS = "gift_cards",
  ARTICLE_PAGE = "article_page",
  SHOP_CATALOG = "shop_catalog",
  LOST_PASSWORD = "lost_password",
  SEARCH_RESULT = "search_results",
  PRIVACY_POLICY = "privacy_policy",
  SINGLE_PRODUCT = "single_product",
  PERSONAL_ACCOUNT = "personal_account",
  DELIVERY_PAYMENT = "delivery_payment",
  LOGIN_REGISTRATION = "login_registration",
  PERSONAL_ACCOUNT_ORDERS = "personal_account_orders",
  PERSONAL_ACCOUNT_WISH_LIST = "personal_account_wish_list",
  PERSONAL_ACCOUNT_MY_PROFILE = "personal_account_my_profile",
  PERSONAL_ACCOUNT_SINGLE_ORDER = "personal_account_single_order",
}
