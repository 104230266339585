import { LOCAL_STORAGE_KEYS } from "@/core/enums/common/local_storage.enum";
import type { ILoginData } from "@/core/interfaces/responses/auth-respones.interface";
import { LocalStorageService } from "@/services/localStorageService";

export const setLoginDataInStorage = (loginData: ILoginData) => {
  const { access_token, refresh_token, expires_in, token_type, user } =
    loginData;

  const dataToStorage = {
    [LOCAL_STORAGE_KEYS.AUTH_TOKEN]: access_token,
    [LOCAL_STORAGE_KEYS.REFRESH_TOKEN]: refresh_token,
    [LOCAL_STORAGE_KEYS.TOKEN_VALIDITY]: expires_in,
    [LOCAL_STORAGE_KEYS.TOKEN_TYPE]: token_type,
    [LOCAL_STORAGE_KEYS.USER_ID]: user.user_id,
  };

  Object.entries(dataToStorage).forEach(([key, value]) => {
    LocalStorageService.set(key as LOCAL_STORAGE_KEYS, value);
  });
};
