import { LOCAL_STORAGE_KEYS } from "@/core/enums/common";
import { LocalStorageService } from "@/services/localStorageService";

export const isTokenExpired = () => {
  const lifeTime = LocalStorageService.read(LOCAL_STORAGE_KEYS.TOKEN_VALIDITY);

  if (!lifeTime) return true;

  const now = Math.trunc(Date.now() / 1000);

  return Number(lifeTime) - now < 60;
};
