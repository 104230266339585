import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from "vue-router";
import { ROUTER_URLS } from "@/core/enums/router";
import { useAuthStore } from "@/stores/authStore";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: ROUTER_URLS.SIGN_IN,
      component: () => import("../views/Auth/SignIn/SignIn.vue"),
      meta: { withAuthCheck: false },
    },
    {
      path: ROUTER_URLS.HOME,
      component: () => import("../views/Dashboard/Dashboard.vue"),
      meta: { withAuthCheck: true },
    },
    {
      path: ROUTER_URLS.CLIENTS,
      component: () => import("../views/Clients/Clients.vue"),
      meta: { withAuthCheck: true },
    },
    {
      path: ROUTER_URLS.PROJECTS,
      component: () => import("../views/Projects/Projects.vue"),
      children: [
        {
          path: ROUTER_URLS.NEW_PROJECT_CLIENT_INFORMATION,
          component: () =>
            import("../views/Projects/ClientInformation/ClientInformation.vue"),
          meta: { withAuthCheck: true },
        },
        {
          path: ROUTER_URLS.NEW_PROJECT_COMPANY_INFORMATION,
          component: () =>
            import(
              "../views/Projects/CompanyInformation/CompanyInformation.vue"
            ),
          meta: { withAuthCheck: true },
        },
        {
          path: ROUTER_URLS.NEW_PROJECT_SERVICES,
          component: () => import("../views/Projects/Services/Services.vue"),
          meta: { withAuthCheck: true },
        },
        {
          path: ROUTER_URLS.NEW_PROJECT_ONLINE_STORE,
          component: () =>
            import("../views/Projects/OnlineStore/OnlineStore.vue"),
          meta: { withAuthCheck: true },
        },
      ],
      meta: { withAuthCheck: true },
    },
    {
      path: ROUTER_URLS.ANALYTIC,
      component: () => import("../views/Analytic/Analytic.vue"),
      meta: { withAuthCheck: true },
    },
    {
      path: ROUTER_URLS.SETTINGS,
      component: () => import("../views/Settings/Settings.vue"),
      meta: { withAuthCheck: true },
    },
    { path: "/", redirect: ROUTER_URLS.HOME },
  ],
});

router.beforeEach(
  (to: RouteLocationNormalized, _from: RouteLocationNormalized) => {
    if (to.meta.withAuthCheck) {
      const { isAuth } = useAuthStore();

      return isAuth || { path: ROUTER_URLS.SIGN_IN };
    }
  }
);

export default router;
