import type { LOCAL_STORAGE_KEYS } from "@/core/enums/common/local_storage.enum";

class LocalStorageService {
  static set = (key: LOCAL_STORAGE_KEYS, value: any) =>
    localStorage.setItem(key, value);

  static read = (key: LOCAL_STORAGE_KEYS) => localStorage.getItem(key);

  static remove = (key: LOCAL_STORAGE_KEYS) => localStorage.removeItem(key);

  static clear = () => localStorage.clear();
}

export { LocalStorageService };
