<template>
  <div class="checkbox">
    <label :class="`checkbox__label ${disabled ? 'disabled' : ''}`">
      <input
        type="checkbox"
        :value="value"
        :id="value"
        :disabled="disabled"
        @change="changeHandler"
      />

      <div class="checkbox__label--indicator">
        <svg
          class="svg"
          x="0px"
          y="0px"
          viewBox="0 0 135 110"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path class="check" d="M126.8,14L55.7,85.1L29.2,63.4" />
        </svg>
      </div>

      <div class="checkbox__label--title">{{ title }}</div>
    </label>
  </div>
</template>

<script setup lang="ts">
import type {
  ICheckboxProps,
  ICheckboxEmits,
} from "@/core/interfaces/components";

defineProps<ICheckboxProps>();
const emit = defineEmits<ICheckboxEmits>();

function changeHandler(ev: Event) {
  const { value, checked } = ev.target as HTMLInputElement;

  emit("change", value, checked);
}
</script>

<style scoped lang="scss">
@import "./CheckBox.scss";
</style>
