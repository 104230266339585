import { ROUTER_URLS } from "../enums/router/router_urls.enum";
import type { StepConfigs } from "../interfaces/components/projects.interface";

export const subPathArray = [
  ROUTER_URLS.NEW_PROJECT_CLIENT_INFORMATION,
  ROUTER_URLS.NEW_PROJECT_COMPANY_INFORMATION,
  ROUTER_URLS.NEW_PROJECT_SERVICES,
  ROUTER_URLS.NEW_PROJECT_ONLINE_STORE,
];

export const stepConfig: StepConfigs = {
  1: {
    path:
      ROUTER_URLS.PROJECTS + "/" + ROUTER_URLS.NEW_PROJECT_CLIENT_INFORMATION,
    fnName: "createClient",
    stepAllowed: false,
    triggerStep: false,
  },
  2: {
    path:
      ROUTER_URLS.PROJECTS + "/" + ROUTER_URLS.NEW_PROJECT_COMPANY_INFORMATION,
    fnName: "createClient",
    stepAllowed: false,
    triggerStep: false,
  },
  3: {
    path: ROUTER_URLS.PROJECTS + "/" + ROUTER_URLS.NEW_PROJECT_SERVICES,
    fnName: "createClient",
    stepAllowed: false,
    triggerStep: false,
  },
  4: {
    path: ROUTER_URLS.PROJECTS + "/" + ROUTER_URLS.NEW_PROJECT_ONLINE_STORE,
    fnName: "createClient",
    stepAllowed: false,
    triggerStep: false,
  },
};
