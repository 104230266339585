import type { AxiosResponse } from "axios";
import { toast, type ToastOptions } from "vue3-toastify";
import { ProjectService } from "@/services/projectService";
import type {
  ICreateClientBody,
  ICreateCompanyBody,
  ICreateProjectBody,
} from "../../core/interfaces/requests/project-requests.interface";
import type {
  ICreateClientResponse,
  ICreateCompanyResponse,
} from "../../core/interfaces/responses/project-responses.interface";

export const createClient = async (
  payload: ICreateClientBody
): Promise<AxiosResponse<ICreateClientResponse> | undefined> => {
  const clientResponse = await ProjectService.createClient(payload);

  if (!clientResponse) {
    toast("Something went wrong", {
      autoClose: 2000,
      type: toast.TYPE.ERROR,
      position: toast.POSITION.BOTTOM_RIGHT,
    } as ToastOptions);
    return;
  }

  return clientResponse;
};

export const createCompany = async (
  payload: ICreateCompanyBody
): Promise<AxiosResponse<ICreateCompanyResponse> | undefined> => {
  const clientResponse = await ProjectService.createCompany(payload);

  if (!clientResponse) {
    toast("Something went wrong", {
      autoClose: 2000,
      type: toast.TYPE.ERROR,
      position: toast.POSITION.BOTTOM_RIGHT,
    } as ToastOptions);
    return;
  }

  return clientResponse;
};

export const createProject = async (
  payload: ICreateProjectBody
): Promise<AxiosResponse<ICreateProjectBody> | undefined> => {
  const clientResponse = await ProjectService.createProject(payload);

  if (!clientResponse) {
    toast("Something went wrong", {
      autoClose: 2000,
      type: toast.TYPE.ERROR,
      position: toast.POSITION.BOTTOM_RIGHT,
    } as ToastOptions);
    return;
  }

  return clientResponse;
};
