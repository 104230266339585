<template>
  <nav class="nav-bar">
    <ul class="nav-bar__list">
      <li
        v-for="item in NAV_BAR_CONFIG"
        :class="`nav-bar__list--item ${
          route.path.includes(item.path) ? 'active' : ''
        }`"
        @click="() => handleNavigate(item)"
      >
        <div class="nav-bar__list--item-content">
          <img
            :src="
              route.path.includes(item.path)
                ? item.activeIcon
                : item.passiveIcon
            "
            alt="icon"
          />

          <span>{{ item.title }}</span>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { NAV_BAR_CONFIG } from "@/core/constants";
import { NAVBAR_NAMES } from "@/core/enums/components/navbar_names.enum";
import type { INavBarInterface } from "@/core/interfaces/components/navbar.interface";
import { useAuthStore } from "@/stores/authStore";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const { logout } = useAuthStore();

const handleNavigate = (navbarItem: INavBarInterface) => {
  const { title, path } = navbarItem;

  if (title === NAVBAR_NAMES.LOG_OUT) {
    logout();
  }

  router.push({ path });
};
</script>

<style scoped lang="scss">
@import "./NavBar.scss";
</style>
