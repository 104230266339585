import { SECTION_NAMES } from "../enums/components";
import type { ISelector } from "../interfaces/components";

export const PAGES_SELECTOR: ISelector[] = [
  {
    value: SECTION_NAMES.HOME,
    title: "Home Page",
  },
  {
    value: SECTION_NAMES.SHOP_CATALOG,
    title: "Shop/Catalog",
  },
  {
    value: SECTION_NAMES.SINGLE_PRODUCT,
    title: "Single Product",
  },
  {
    value: SECTION_NAMES.CART,
    title: "Cart",
  },
  {
    value: SECTION_NAMES.CHECKOUT,
    title: "Checkout",
  },
  {
    value: SECTION_NAMES.THANK_YOU,
    title: "Thank you page",
  },
  {
    value: SECTION_NAMES.WISHLIST,
    title: "Wishlist",
  },
  {
    value: SECTION_NAMES.GIFT_CARDS,
    title: "Gift cards",
  },
  {
    value: SECTION_NAMES.SEARCH_RESULT,
    title: "Search results(products)",
  },
  {
    value: SECTION_NAMES.ABOUT_US,
    title: "About Us",
  },
  {
    value: SECTION_NAMES.FAQ,
    title: "FAQ",
  },
  {
    value: SECTION_NAMES.DELIVERY_PAYMENT,
    title: "Delivery/Payment",
  },
  {
    value: SECTION_NAMES.PRIVACY_POLICY,
    title: "Privacy Policy",
  },
  {
    value: SECTION_NAMES.CONTACT_US,
    title: "Contact Us",
  },
  {
    value: SECTION_NAMES.BLOG,
    title: "Blog",
  },
  {
    value: SECTION_NAMES.ARTICLE_PAGE,
    title: "Article Page",
  },
  {
    value: SECTION_NAMES.NOT_FOUND,
    title: "404",
  },
  {
    value: SECTION_NAMES.SITEMAP,
    title: "Sitemap",
  },
  {
    value: SECTION_NAMES.LOGIN_REGISTRATION,
    title: "Login/Registration",
  },
  {
    value: SECTION_NAMES.LOST_PASSWORD,
    title: "Lost Password",
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT,
    title: "Personal Account",
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT_ORDERS,
    title: "Personal Account Orders",
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT_SINGLE_ORDER,
    title: "Personal Account Single Order",
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT_WISH_LIST,
    title: "Personal Account Wish List",
  },
  {
    value: SECTION_NAMES.PERSONAL_ACCOUNT_MY_PROFILE,
    title: "Personal Account My Profile",
  },
];
