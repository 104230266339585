export const getBreadCrumbPath = (routePath: string) => {
  const breadCrumbPath = routePath
    .split("/")
    .filter((path: string) => !!path)
    .map((path: string) => path.charAt(0).toUpperCase() + path.slice(1));

  if (breadCrumbPath.length > 1) {
    breadCrumbPath.splice(1, 0, "/");
  }

  return breadCrumbPath;
};
