<template>
  <div class="input-phone">
    <span v-if="label" class="input-phone__label">{{ label || "" }}</span>
    <vue-tel-input
      v-model="inputValue"
      :autoDefaultCountry="true"
      :validCharactersOnly="true"
      :styleClasses="['input-phone__input']"
      :dropdownOptions="PHONE_INPUT_OPTIONS.DROPDOWN"
      :inputOptions="PHONE_INPUT_OPTIONS.INPUT"
      @open="isOpenSelect = true"
      @close="isOpenSelect = false"
      @focus="() => (isInputFocus = true)"
      @validate="(phoneObject: object) => handleValidateInput(phoneObject)"
      @on-input="(number: string, _phoneObject: object) => emit('input-change-event', number as string)"
    >
      <template #arrow-icon>
        <CustomIcon
          :class="`input-phone__dropdown-arrow ${
            isOpenSelect ? 'input-phone__dropdown-arrow--open' : ''
          }`"
          :icon-type="ICON_TYPE.SELECT_ARROW"
        />
      </template>
    </vue-tel-input>

    <div v-show="inputValue" class="input-phone__close-button">
      <CustomIcon @click="handleClearInput" :icon-type="ICON_TYPE.CLOSE" />
    </div>

    <p v-show="!isValidValue || !!errorMessage" class="input-phone__error">
      {{ !!errorMessage ? errorMessage : INPUT.ERROR.NOT_VALID_NUMBER }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ICON_TYPE } from "@/core/enums/components";
import CustomIcon from "../CustomIcon/CustomIcon.vue";
import { ref } from "vue";
import { VueTelInput } from "vue-tel-input";
import { PHONE_INPUT_OPTIONS } from "@/core/constants";
import type {
  IPhoneInputEmits,
  IPhoneInputProps,
} from "@/core/interfaces/components";
import { INPUT } from "@/core/constants";

const emit = defineEmits<IPhoneInputEmits>();
const { value, errorMessage } = defineProps<IPhoneInputProps>();

const inputValue = ref<string | number>(value);
const isValidValue = ref<boolean>(true);
const isOpenSelect = ref<boolean>(false);
const isInputFocus = ref<boolean>(false);

const handleClearInput = (): void => {
  emit("input-clear");
  inputValue.value = "";
};

const handleValidateInput = (inputData: any): void => {
  if (!isInputFocus.value) return;
  if (inputData?.valid === true && inputData?.number) {
    isValidValue.value = true;
  } else if (inputData?.valid === false || !inputData?.number) {
    isValidValue.value = false;
  }
};
</script>

<style scoped lang="scss">
@import "./PhoneInput.scss";
</style>
