<template>
  <img
    :class="className"
    :alt="CRM_ICONS[iconType].alt"
    :src="CRM_ICONS[iconType].src"
    @click="() => emit('click')"
  />
</template>

<script lang="ts" setup>
import {
  type ICustomIconEmits,
  type ICustomIconProps,
} from "@/core/interfaces/components";
import { CRM_ICONS } from "@/core/constants";
const emit = defineEmits<ICustomIconEmits>();
defineProps<ICustomIconProps>();
</script>

<style lang="scss" scoped>
@import "./CustomIcon.scss";
</style>
